export interface IPageProps {
  setSelectedPage: (
    page:
      | "LandingPage"
      | "Dashboard"
      | "CashFlowForecast"
      | "SingleRecommendation"
  ) => void;
}

export enum TransactionType {
  Debit = 1,
  Credit = 2,
}

export enum MXTransactionType {
  Credit = 1,
  Debit = 2,
}

export enum DailyTransactionType {
  Past = 1,
  Future = 2,
}

export interface IDailyTransaction {
  date: string;
  inflow: number;
  outflow: number;
  type: DailyTransactionType;
}

export interface IControlLimits {
  UCL: number;
  LCL: number;
}

export interface IDailyNet {
  date: string;
  dailyNet: number;
}

export interface IChartData {
  day: number;
  DailyNet: number;
}

export interface IChartTransaction {
  amount: number;
  date: string;
  type: TransactionType;
}
