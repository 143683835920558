export const conversionTable = [
    { dpmo: 933193, score: 0 },
    { dpmo: 857752, score: 2 },
    { dpmo: 788410, score: 4 },
    { dpmo: 724673, score: 6 },
    { dpmo: 666089, score: 8 },
    { dpmo: 612241, score: 10 },
    { dpmo: 562746, score: 12 },
    { dpmo: 517253, score: 14 },
    { dpmo: 475437, score: 16 },
    { dpmo: 437002, score: 18 },
    { dpmo: 401674, score: 20 },
    { dpmo: 369202, score: 22 },
    { dpmo: 339355, score: 24 },
    { dpmo: 311921, score: 27 },
    { dpmo: 286704, score: 29 },
    { dpmo: 263527, score: 31 },
    { dpmo: 242223, score: 33 },
    { dpmo: 222641, score: 35 },
    { dpmo: 204642, score: 37 },
    { dpmo: 188098, score: 39 },
    { dpmo: 172892, score: 41 },
    { dpmo: 158915, score: 43 },
    { dpmo: 146068, score: 45 },
    { dpmo: 134260, score: 47 },
    { dpmo: 123406, score: 49 },
    { dpmo: 113430, score: 51 },
    { dpmo: 104260, score: 53 },
    { dpmo: 95831, score: 55 },
    { dpmo: 88084, score: 57 },
    { dpmo: 80963, score: 59 },
    { dpmo: 74418, score: 61 },
    { dpmo: 68402, score: 63 },
    { dpmo: 62872, score: 65 },
    { dpmo: 57789, score: 67 },
    { dpmo: 53118, score: 69 },
    { dpmo: 48823, score: 71 },
    { dpmo: 44876, score: 73 },
    { dpmo: 41249, score: 76 },
    { dpmo: 37914, score: 78 },
    { dpmo: 34849, score: 80 },
    { dpmo: 32032, score: 82 },
    { dpmo: 29442, score: 84 },
    { dpmo: 27062, score: 86 },
    { dpmo: 24874, score: 88 },
    { dpmo: 22863, score: 90 },
    { dpmo: 21015, score: 92 },
    { dpmo: 19316, score: 94 },
    { dpmo: 17755, score: 96 },
    { dpmo: 16319, score: 98 },
    { dpmo: 15000, score: 100 },
    { dpmo: 0, score: 100 },
];
