import {
  TransactionType,
  IDailyTransaction,
  DailyTransactionType,
  IDailyNet,
  MXTransactionType,
  // IControlLimits,
} from "../models/common";
import { IMxAccount, IMxTransaction } from "../store/modules/MXAPI/models";
import { IAccount, ITransaction } from "../store/modules/NarmiAPI/models";
import { formatMoney, sumBalances } from "./common";
import {
  getNarmiTransactionsByType,
  getMXTransactionsByType,
  isNarmiTransaction,
} from "./getTransactionsByType";
import { conversionTable } from "./sixSigmaConversionTable";

//Calculation for Aggregated Accounts

export function calculateAggregatedAccounts(
  narmiAccountsLength: number,
  mxAccountsLength: number
) {
  return narmiAccountsLength + mxAccountsLength;
}

//Calculation for Cash on Deposit

export function calculateCashOnDeposit(narmiAccounts: IAccount[]) {
  const cashOnDepositAcc = narmiAccounts.filter(
    (account) => account.product.description === "LIAFI BUSINESS"
  );
  const cashOnDeposit = cashOnDepositAcc[0]?.balances?.available
    ? formatMoney(
        parseFloat((cashOnDepositAcc[0].balances.available / 100).toFixed(2))
      )
    : "0.00";

  return cashOnDeposit;
}

//Calculation for Cash on Hand

export function calculateCashOnHand(
  narmiAccounts: IAccount[],
  mxAccounts: IMxAccount[]
) {

  const narmiAccountBalances = sumBalances(
    narmiAccounts.map((account) =>
      account.balances.available
        ? parseFloat((account.balances.available / 100).toFixed(2))
        : 0
    )
  );

  const mxAccountBalances = sumBalances(
    mxAccounts.map((account) => account.available_balance)
  );

  const cashOnHand = formatMoney(narmiAccountBalances + mxAccountBalances);

  return cashOnHand;
}

//Calculation for Cash on Reserve

export function calculateCashOnReserve(
  debitTransactions: number[],
  narmiAccounts: IAccount[],
  mxAccounts: IMxAccount[]
) {

  const narmiAccountBalances = sumBalances(
    narmiAccounts.map((account) =>
      account.balances.available
        ? parseFloat((account.balances.available / 100).toFixed(2))
        : 0
    )
  );

  const mxAccountBalances = sumBalances(
    mxAccounts.map((account) => account.available_balance || 0)
  );

  const totalAccountsBalance = narmiAccountBalances + mxAccountBalances;

  const monthAverage = sumBalances(debitTransactions) / 3;

  if (monthAverage === 0) {
    return 0; 
  }

  const cashOnReserveInDays = totalAccountsBalance / monthAverage;

  const cashOnReserveInMonths = cashOnReserveInDays / 30;

  const roundedCashOnReserve =
  cashOnReserveInMonths < 0
    ? 0
    : cashOnReserveInMonths % 1 >= 0.5
    ? Math.ceil(cashOnReserveInMonths)
    : Math.floor(cashOnReserveInMonths);

  return roundedCashOnReserve;
}

//Calculation for UCL and LCL

export function calculateControlLimits(
  narmiTransactions: ITransaction[],
  mxTransactions: IMxTransaction[]
) {
  const narmiDebitTransactionsLimitsData = getNarmiTransactionsByType(
    narmiTransactions,
    TransactionType.Debit
  );
  const mxDebitTransactionsLimitsData = getMXTransactionsByType(
    mxTransactions,
    MXTransactionType.Debit
  );
  const narmiCreditTransactionsLimitsData = getNarmiTransactionsByType(
    narmiTransactions,
    TransactionType.Credit
  );
  const mxCreditTransactionsLimitsData = getMXTransactionsByType(
    mxTransactions,
    MXTransactionType.Credit
  );

  const inflows = sumBalances([
    ...narmiCreditTransactionsLimitsData,
    ...mxCreditTransactionsLimitsData,
  ]);
  const outflows = sumBalances([
    ...narmiDebitTransactionsLimitsData,
    ...mxDebitTransactionsLimitsData,
  ]);
  const avgDailyNet = (inflows - outflows) / 90;

  const UCL = avgDailyNet * 1.4;
  const LCL = avgDailyNet * 0.8;

  return { UCL, LCL };
}

//Calculation for YTD

export function calculateYTD(narmiTransactions: ITransaction[]) {

  const lastDayOfYear = new Date(new Date().getFullYear(), 11, 31)
    .toISOString()
    .split("T")[0];
  const transactionsYTD = narmiTransactions
    .filter(
      (transaction) =>
        transaction.raw_description === "Interest" &&
        transaction.settled_at <= lastDayOfYear
    )
    .map((transaction) => parseFloat((transaction.amount / 100).toFixed(2)));

  const totalDividends = sumBalances(transactionsYTD);

  return formatMoney(totalDividends);
}

//Calculation for Forecasted Expenses

export function calculateForecastedExpenses(debitTransactions: number[]) {

  const forecastedExpenses = formatMoney(sumBalances(debitTransactions) / 3);

  return forecastedExpenses;
}

//Calculations for Daily Transactions

export function calculateDailyTransactions(
  transactions: (IMxTransaction | ITransaction)[],
  range: number
): IDailyTransaction[] {
  const dailyTransactions: Record<string, { inflow: number; outflow: number }> =
    {};

  transactions.forEach((transaction) => {
    const date = isNarmiTransaction(transaction)
      ? new Date(transaction.settled_at).toISOString().split("T")[0]
      : new Date(transaction.date).toISOString().split("T")[0];

    if (!dailyTransactions[date]) {
      dailyTransactions[date] = { inflow: 0, outflow: 0 };
    }

    const amount = isNarmiTransaction(transaction)
      ? parseFloat((transaction.amount / 100).toFixed(2))
      : transaction.amount;

    console.log(`Date: ${date}, Amount: ${amount}, Type: ${isNarmiTransaction(transaction) ? transaction.type :  transaction.transaction_type}`);

    if (isNarmiTransaction(transaction)) {
      if (amount > 0) {
        dailyTransactions[date].inflow += amount;
      } else {
        dailyTransactions[date].outflow +=  Math.abs(amount);
      }
    } else {
      if (transaction.transaction_type === MXTransactionType.Credit) {
        dailyTransactions[date].inflow += amount;
      } else {
        dailyTransactions[date].outflow += amount;
      }
    }
  });

  const type = DailyTransactionType.Future;

  let dailyTransactions1 = Object.entries(dailyTransactions).map(
    ([date, { inflow, outflow }]) => ({
      date,
      inflow,
      outflow,
      type,
    })
  );

  const transactionMap = dailyTransactions1.reduce(
    (map: { [key: string]: IDailyTransaction }, transaction) => {
      map[transaction.date] = transaction;
      return map;
    },
    {}
  );

  const currentDate = new Date();
  const toDate = new Date();
  currentDate.setDate(currentDate.getDate() - (range - 1));
  const fromDate = currentDate.toISOString().split("T")[0];

  const allDates = getDatesInRange(
    fromDate,
    toDate.toISOString().split("T")[0]
  );

  const result = allDates.map((date) => {
    if (transactionMap[date]) {
      return transactionMap[date];
    } else {
      return {
        date,
        inflow: 0,
        outflow: 0,
        type: DailyTransactionType.Past,
      };
    }
  });

  return result;
}

function getDatesInRange(fromDate: string, toDate: string) {
  const dateArray = [];
  let currentDate = new Date(fromDate);

  while (currentDate <= new Date(toDate)) {
    dateArray.push(new Date(currentDate).toISOString().split("T")[0]);
    currentDate.setDate(currentDate.getDate() + 1);
  }
  return dateArray;
}

//Calculations for TOR Score

export function calculateTorScore(
  narmiTransactions: ITransaction[],
  mxTransactions: IMxTransaction[]
) {

  const dailyTransactions = calculateDailyTransactions(
    [...narmiTransactions, ...mxTransactions],
    90
  );

  const dailyNets = calculateDailyNet(dailyTransactions);

  const filteredDailyNets = dailyNets.filter(({ dailyNet }) => dailyNet !== 0);

  console.log('DailyNets', filteredDailyNets);

  const totalNet = filteredDailyNets.reduce(
    (sum, { dailyNet }) => sum + dailyNet,
    0
  );

  console.log('TotalNet', totalNet);

  const avgDailyNet = totalNet / filteredDailyNets.length;

  console.log('AvgDailyNet', avgDailyNet);


  const threshold =
    avgDailyNet < 0
      ? avgDailyNet * 1.2 // Use 120% for negative average daily net
      : avgDailyNet * 0.8; // Use 80% for positive average daily net

  
  console.log('Threshold', threshold);

  const defects = identifyDefects(filteredDailyNets, threshold);

  console.log('Defects', defects.length);


  const DPMO = (defects.length / filteredDailyNets.length) * 1000000;

  console.log('DPMO', DPMO);

  const torScore = parseInt(interpolate(DPMO).toFixed(0));

  console.log('TorScore', torScore);

  return torScore;
}

export function identifyDefects(
  transactions: IDailyNet[],
  threshold: number
) {
  const defects = transactions
    .map((transaction) => {
      if (transaction.dailyNet < threshold) return "Defect";
      return null;
    })
    .filter((defect) => defect !== null);

  return defects;
}

export function interpolate(dpmo: number) {
  let lowerBound = conversionTable[0];
  let upperBound = conversionTable[conversionTable.length - 1];

  for (let i = 0; i < conversionTable.length - 1; i++) {
    if (dpmo >= conversionTable[i + 1].dpmo) {
      lowerBound = conversionTable[i];
      upperBound = conversionTable[i + 1];
      break;
    }
  }

  const { dpmo: dpmo1, score: score1 } = lowerBound;
  const { dpmo: dpmo2, score: score2 } = upperBound;

  const score = score1 + ((dpmo - dpmo1) / (dpmo2 - dpmo1)) * (score2 - score1);
  return score;
}

export function calculateDailyNet(transactions: IDailyTransaction[]) {
  return transactions.map((transaction) => {
    const { date, inflow, outflow } = transaction;
    const dailyNet = (inflow - outflow);
    return { date, dailyNet };
  });
}

export function getTorScoreLabel(rate: number) {
  if (rate >= 0 && rate <= 33) {
    return "Needs Attention";
  } else if (rate >= 34 && rate <= 66) {
    return "Good";
  } else if (rate >= 67 && rate <= 100) {
    return "Perfect";
  } else {
    return "Invalid input";
  }
}

//Calculation for Total Income / Expense

export function calculateTotalIncomeExpense(
  narmiTransactionsData: ITransaction[],
  mxTransactionsDataAll: IMxTransaction[],
  TransactionType: number,
  MXTransactionType: number
) {
  const narmiTransactions = getNarmiTransactionsByType(
    narmiTransactionsData,
    TransactionType
  );
  const mxTransactions = getMXTransactionsByType(
    mxTransactionsDataAll,
    MXTransactionType
  );

  const total = sumBalances([...narmiTransactions, ...mxTransactions]);
  return total;
}
