import { useDispatch } from "react-redux";
import {
  setIsAuthorized,
  setIsLoadingAccountDetails,
} from "../store/slices/authSlice";
import NarmiAPI from "../store/modules/NarmiAPI";
import MXAPI from "../store/modules/MXAPI";
import { useEffect, useState } from "react";
import {
  calculateAggregatedAccounts,
  calculateCashOnHand,
  calculateCashOnDeposit,
  calculateForecastedExpenses,
  calculateCashOnReserve,
  calculateTorScore,
} from "../utils/calculations";
import {
  getNarmiTransactionsByType,
  getMXTransactionsByType,
} from "../utils/getTransactionsByType";
import { TransactionType, MXTransactionType } from "../models/common";
import { showAlert } from "../store/slices/alertSlice";
import { showAlertAccounts } from "../store/slices/alertAccountsSlice";

const ENV = process.env.REACT_APP_ENV || "development";

export interface IMXConnectedAccounts {
  totalConnectedAccounts: number;
  accounts: { check_micr: string; connection_status: string }[];
}

const useAccountDetails = () => {
  const dispatch = useDispatch();
  const [getNarmiUserAccounts] = NarmiAPI().useLazyGetUserAccountsQuery();
  const [getNarmiUserTransactions] =
    NarmiAPI().useLazyGetUserTransactionsQuery();
  const [getMXUserAccounts] = MXAPI().useLazyGetMXUserAccountsQuery();
  const [getMXMembers] = MXAPI().useLazyGetMXMembersQuery();

  const [getMXUserTransactions] =
    MXAPI().useLazyGetMXUserTransactionsByAccountQuery();
  const [cashOnHand, setCashOnHand] = useState("0.00");
  const [cashOnDeposit, setCashOnDeposit] = useState("0.00");
  const [cashReserve, setCashReserve] = useState(0);
  const [accountsCount, setAccountsCount] = useState(0);
  const [forecastedExpenses, setForecastedExpenses] = useState("0.00");
  const [cashflowScore, setCashflowScore] = useState(0);
  const [mxConnectedAccounts, setMXConnectedAccounts] = useState<IMXConnectedAccounts>();

  useEffect(() => {
    const getUserDetails = async () => {
      try {
        dispatch(setIsLoadingAccountDetails(true));

        const narmiAccountData = await getNarmiUserAccounts().unwrap();
        if (narmiAccountData.meta.total === 1) {
          dispatch(showAlertAccounts());
          dispatch(setIsLoadingAccountDetails(false));
          return;
        }

        const mxMembersData = await getMXMembers().unwrap();
        const disconnectedMembers = mxMembersData.members.filter(
          (member) =>
            member.name !== "Liafi" &&
            member.connection_status !== "CONNECTED" 
        );

        const mxAccountData = await getMXUserAccounts().unwrap();

        const externalNarmiAccounts = narmiAccountData.accounts.filter(
          (account) => account.source === "external"
        );

        let checkMicrList: string[] = [];
        if (ENV === "production") {
          checkMicrList = externalNarmiAccounts.map((account) =>
            account.check_micr.slice(-4)
          );
        } else {
          checkMicrList = externalNarmiAccounts.map(
            (account) => account.check_micr
          );
        }

        const disconnectedMemberGuids = disconnectedMembers.map(
          (member) => member.guid
        );
        const matchingMxAccounts = mxAccountData.accounts.filter(
          (mxAccount) =>
            !disconnectedMemberGuids.includes(mxAccount.member_guid) &&
            checkMicrList.includes(mxAccount.account_number)
        );

        const totalConnectedAccounts = externalNarmiAccounts.length;
          
        const mxAccountConnectionMap = new Map();
        mxAccountData.accounts.forEach((account) => {
          const member = mxMembersData.members.find((m) => m.guid === account.member_guid);
          if (member) {
            mxAccountConnectionMap.set(account.account_number, member.connection_status);
          }
        });
        
        const accountsWithStatus = externalNarmiAccounts.map((narmiAccount) => {
          const checkMicr = ENV === "production" ? narmiAccount.check_micr.slice(-4) : narmiAccount.check_micr; 
          const connectionStatus =
            mxAccountConnectionMap.get(checkMicr) || "UNKNOWN";
          return {
            check_micr: checkMicr,
            connection_status: connectionStatus,
          };
        });
        
        const result = {
          totalConnectedAccounts,
          accounts: accountsWithStatus,
        };

        setMXConnectedAccounts(result);

        let threeMonthsAgo = new Date();
        threeMonthsAgo.setDate(threeMonthsAgo.getDate() - 90);
        const fromDate = threeMonthsAgo.toISOString().split("T")[0];

        const toDate = new Date().toISOString().split("T")[0];

        const narmiTransactionsData = await getNarmiUserTransactions({
          settled_from: fromDate,
          settled_to: toDate,
          records_per_page: 1000,
        }).unwrap();

        const mxTransactionsData = await Promise.all(
          matchingMxAccounts.map(async (account) => {
            const transactions = await getMXUserTransactions({
              id: account.guid,
              from_date: fromDate,
              to_date: toDate,
              records_per_page: 1000,
            }).unwrap();
            return transactions.transactions;
          })
        );

        const mxTransactionsDataAll = mxTransactionsData.reduce(
          (acc, curr) => acc.concat(curr),
          []
        );

        //Accounts count
        setAccountsCount(
          calculateAggregatedAccounts(
            narmiAccountData.accounts.length,
            matchingMxAccounts.length
          )
        );

        // Cash on hand
        setCashOnHand(calculateCashOnHand(narmiAccountData.accounts, []));

        // Cash on deposit
        setCashOnDeposit(calculateCashOnDeposit(narmiAccountData.accounts));

        // Forecasted expenses and cash reserve
        const narmiDebitTransactions = getNarmiTransactionsByType(
          narmiTransactionsData.transactions,
          TransactionType.Debit
        );

        const mxDebitTransactions = getMXTransactionsByType(
          mxTransactionsDataAll,
          MXTransactionType.Debit
        );

        setForecastedExpenses(
          calculateForecastedExpenses([
            ...narmiDebitTransactions,
            ...mxDebitTransactions,
          ])
        );

        setCashReserve(
          calculateCashOnReserve(
            [...narmiDebitTransactions, ...mxDebitTransactions],
            narmiAccountData.accounts,
            matchingMxAccounts
          )
        );

        //cashflow score calculation

        // This is for next phase
        // const baseLineBalance = (calculateBaselineBalance(narmiTransactionsData.transactions) + calculateBaselineBalance(mxTransactionsDataAll));
        // const { USL, LSL } = calculateUSLandLSL(baseLineBalance);

        setCashflowScore(
          calculateTorScore(
            narmiTransactionsData.transactions,
            mxTransactionsDataAll
          )
        );
      } catch (error) {
        console.error("Something went wrong");
        dispatch(setIsAuthorized(false));
        dispatch(showAlert());
      } finally {
        dispatch(setIsLoadingAccountDetails(false));
      }
    };

    // This is for next phase
    // function calculateBaselineBalance(transactions: IMxTransaction[] | ITransaction[]) {
    //   const totalBalance = transactions.reduce((sum, transaction) => sum + transaction.amount, 0);
    //   return totalBalance / transactions.length;
    // }

    // function calculateUSLandLSL(baselineBalance: number, customUSL = null, customLSL = null) {
    //   const standardUSL = baselineBalance * 1.4;
    //   const standardLSL = baselineBalance * 0.8;
    //   const USL = customUSL !== null ? customUSL : standardUSL;
    //   const LSL = customLSL !== null ? customLSL : standardLSL;
    //   return { USL, LSL };
    // }

    getUserDetails();
  }, [getNarmiUserAccounts, getMXUserAccounts, dispatch, getNarmiUserTransactions, getMXUserTransactions, getMXMembers]);

  return {
    cashOnHand,
    cashOnDeposit,
    cashReserve,
    accountsCount,
    forecastedExpenses,
    cashflowScore,
    mxConnectedAccounts
  };
};

export default useAccountDetails;
